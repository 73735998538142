<template>
  <component :is="layout" class="App-wrapper">
    <router-view :layout.sync="layout" />
    <report-ready-notifier />
  </component>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { loadScript, persist } from '@/utils'

import { errorLoggingService } from '@hypefactors/shared/js/services/errorLoggingService'
import { Trans } from '@/services/TranslationService'
import { generateUniqueWatcher } from '@hypefactors/shared/js/utils/componentUtilities'
import ReportReadyNotifier from '@hypefactors/shared/js/components/notifiers/ReportReadyNotifier'

/**
 * The main app entry point
 * @module App
 */
export default {

  components: {
    ReportReadyNotifier
  },
  data () {
    return {
      layout: 'Default'
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'hasFilter'])
  },

  watch: {
    /**
     * Watch the user locale for updates and change the site language accordingly
     */
    'currentUser.locale': {
      immediate: true,
      handler: generateUniqueWatcher('changeLanguagePerUserSettings')
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => vm.initApp())
  },

  methods: {
    ...mapActions(['setFilter']),
    initApp () {
      if (window.Cypress) {
        document.body.classList.add('disableAnimations')
        document.body.classList.add('CypressTest')
      }
      this.$ma.setUsername(this.currentUser.id)

      errorLoggingService.identify(this.currentUser, { activeBrand: this.activeBrandId })

      if (!this.hasFilter('brand_scope')) {
        this.setFilter({
          name: 'brand_scope', value: this.activeBrandId
        })
      }

      this.subscribeToPusherDeploymentEvent()

      if (!window.Cypress && !this.$isDevMode) {
        loadScript('//platform.twitter.com/widgets.js', 'twitter-script')
          .catch(() => {})

        loadScript('//connect.facebook.net/en_US/all.js#xfbml=1&version=v2.10&appId=124111774919610', 'facebook-jssdk')
          .catch(() => {})
      }

      this.checkIfUserIsInvitee()
    },
    subscribeToPusherDeploymentEvent () {
      if (window.Cypress) return
      const channel = this.$pusher.subscribe('deployments')
      channel.bind('hypefactors', data => {
        persist('FORCE_RELOAD', true)
      })
    },
    checkIfUserIsInvitee () {
      const preferencesName = 'settings.personal.preferences'

      if (!this.currentUser.has_password && this.$route.name !== preferencesName) {
        this.$router.push({ name: preferencesName })
      }
    },
    changeLanguagePerUserSettings (lang) {
      Trans.changeLanguage(lang || Trans.getUserSupportedLang()).catch((error) => this.$handleError(error, undefined, 'info'))
    }
  }
}
</script>
