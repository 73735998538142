<template>
  <div class="ReportReadyNotifier" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ReportReadyNotifier',
  computed: {
    ...mapGetters(['currentUser'])
  },
  channels () {
    return {
      [`private:user.${this.currentUser.id}`]: {
        '.ReportGenerated': 'handleIncoming'
      }
    }
  },
  methods: {
    handleIncoming (payload) {
      const message = this.$t('components.report_ready_notifier.success_message', {
        name: `<a href="${payload.url}">${payload.name}</a>`
      })

      this.$notify.success({
        message,
        dangerouslyUseHTMLString: true
      })
      this.$bus.$emit(`Websockets/Report/${payload.id}:ready`, payload)
    }
  }
}
</script>
